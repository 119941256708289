<template>
    <v-container>
        <v-row>
            <v-col cols="12">

              <div class="text-h6 text-center" >
                {{ tableTitle }}
                <v-icon 
                  @click="allTime = !allTime">
                  mdi-timetable
                </v-icon>
              </div>

              <v-data-table
                :headers="computedHeaders"
                :header-props="{ sortIcon: null }"
                :items="tableData"
                :sort-by="['points', 'ratio']"
                :sort-desc="[true, true]"
                :items-per-page="25"
                hide-default-footer
                class="elevation-1"
                mobile-breakpoint="0"
              >
                <template #item="{ item, index, headers }">
               <tr>
                <td v-for="(n, i) in headers" :key="i" class="text-center">
                {{ n.value === 'index' ? index + 1 : item[n.value] }}
                </td>
                </tr>
                </template>
              </v-data-table>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { parseISO, addWeeks } from 'date-fns'
export default {
    name: 'GameTable',
    data() {
        return {
            headers: [
              {
                text: '#',
                value: 'index',
                align: 'center',
                width: '0.25%' 
              },
              {
                text: 'Name',
                value: 'name',
                align: 'center'
              },
              { 
                text: 'Spiele', 
                value: 'games',
                align: 'center'
              },
              { 
                text: 'S/N', 
                value: 'ratioText',                
                align: 'center',
                width: '0.25%' 
              },
              { 
                text: 'Punkte', 
                value: 'points',
                align: 'center'
               },
            ],
            allTime: false,
            tableData: [],
            tableTitle: 'Tabelle (Spieltag)'
        }
    },
    created() {
      this.setTableData()
    },
    watch: {
      allTime: function () {
        this.setTableData()
        if(this.allTime) {
          this.tableTitle = 'Tabelle (Alle)'
        } else {
          this.tableTitle = 'Tabelle (Spieltag)'
        }
      },
      items: function () {
        this.setTableData()
      }
    },
    methods: {
      setTableData() {
        let items = [
          {
            name: 'Robert',
            games: 0,
            win: 0,
            loose: 0,
            ratio: 0,
            ratioText: 0,
            points: 0,
          },
          {
            name: 'Sandra',
            games: 0,
            win: 0,
            loose: 0,
            ratio: 0,
            ratioText: 0,
            points: 0,
          },
          {
            name: 'Belkis',
            games: 0,
            win: 0,
            loose: 0,
            ratio: 0,
            ratioText: 0,
            points: 0,
          },
          {
            name: 'Lech',
            games: 0,
            win: 0,
            loose: 0,
            ratio: 0,
            ratioText: 0,
            points: 0,
          },
          {
            name: 'Maria',
            games: 0,
            win: 0,
            loose: 0,
            ratio: 0,
            ratioText: 0,
            points: 0,
          },
          {
            name: 'Ronny',
            games: 0,
            win: 0,
            loose: 0,
            ratio: 0,
            ratioText: 0,
            points: 0,
          },
          {
            name: 'Felix',
            games: 0,
            win: 0,
            loose: 0,
            ratio: 0,
            ratioText: 0,
            points: 0,
          },
          {
            name: 'Kolbe',
            games: 0,
            win: 0,
            loose: 0,
            ratio: 0,
            ratioText: 0,
            points: 0,
          },
          {
            name: 'Stephan',
            games: 0,
            win: 0,
            loose: 0,
            ratio: 0,
            ratioText: 0,
            points: 0,
          },
          {
            name: 'Toni',
            games: 0,
            win: 0,
            loose: 0,
            ratio: 0,
            ratioText: 0,
            points: 0,
          },
          {
            name: 'Seidel',
            games: 0,
            win: 0,
            loose: 0,
            ratio: 0,
            ratioText: 0,
            points: 0,
          },
          {
            name: 'Bummi',
            games: 0,
            win: 0,
            loose: 0,
            ratio: 0,
            ratioText: 0,
            points: 0,
          },
          {
            name: 'Alex',
            games: 0,
            win: 0,
            loose: 0,
            ratio: 0,
            ratioText: 0,
            points: 0,
          },
          {
            name: 'Henry',
            games: 0,
            win: 0,
            loose: 0,
            ratio: 0,
            ratioText: 0,
            points: 0,
          },
        ]
        this.items.forEach(days => {
          if(this.allTime || addWeeks(parseISO(days.end_date), 1) < new Date()){
            days.games.forEach(game => {
              let homeEntry = items.find(el => el.name === game.home_user.name)
              let guestEntry = items.find(el => el.name === game.guest_user.name)

              let homePoints = game.home_points
              let guestPoints = game.guest_points

              if(homePoints % 1 != 0){
                homePoints = Number(homePoints).toFixed(1)
              }

              if(guestPoints % 1 != 0){
                guestPoints = Number(guestPoints).toFixed(1)
              }

              homeEntry.win += parseFloat(Number(homePoints).toFixed(1))
              homeEntry.loose += parseFloat(Number(guestPoints).toFixed(1))
              homeEntry.ratio = homeEntry.win - homeEntry.loose
              homeEntry.ratioText = `${homeEntry.win}:${homeEntry.loose}`
              if(homePoints > guestPoints) {
                homeEntry.points += 2
              }
              guestEntry.win += parseFloat(Number(guestPoints).toFixed(1))
              guestEntry.loose += parseFloat(Number(homePoints).toFixed(1))
              guestEntry.ratio = parseFloat(Number(guestEntry.win).toFixed(1)) - parseFloat(Number(guestEntry.loose).toFixed(1))
              guestEntry.ratioText = `${guestEntry.win}:${guestEntry.loose}`
              if(guestPoints > homePoints) {
                guestEntry.points += 2
              }
              
              if(parseFloat(Number(homePoints).toFixed(1)) + parseFloat(Number(guestPoints).toFixed(1)) === 5){
                guestEntry.games += 1
                homeEntry.games += 1
              }

              if(Number(homePoints) >0 && parseFloat(Number(homePoints).toFixed(1)) === parseFloat(Number(guestPoints).toFixed(1))) {
                homeEntry.points += 1
                guestEntry.points += 1
              }
            })
          }
          
        })

        this.tableData = items
      }
    },
    computed: {
      items() { return this.$store.state.gameDays },
      computedHeaders() {
        return this.headers.filter(header => header.text !== "ratio")
      }
    },
}
</script>
<style lang="scss" scoped>
    form{
        
    }
</style>