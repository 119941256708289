<template>
    <v-container>
        <v-row>
            <v-col cols="12">
              <v-form @submit="changePassword">
                <v-select
                    label="Name"
                    :items="items"
                    item-text="name"
                    item-value="id"
                    v-model="user"
                  ></v-select>
                <v-text-field
                    label="Passwort"
                    v-model="password"
                  ></v-text-field>
            <v-btn @click="changePassword">Password speichern</v-btn>
          </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import axios from 'axios'
export default {
  name: 'RealAdmin',
  data() {
      return {
        user: null,
        password: null,
        items: {}
      }
  },
  mounted(){
    axios.get(`https://api.glomb-on-line.de/api/users`)
           .then(({ data }) => {
                this.items = data.data
            })
  },
  methods: {
    changePassword() {
      axios.post(`https://api.glomb-on-line.de/api/user/password`, {"user": this.user, "password": this.password})
    },
  },
  computed: {
  },
}
</script>
<style lang="scss" scoped>
    form{
        
    }
</style>