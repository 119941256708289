<template>
    <v-container>
        <v-row>
          <v-snackbar
              :timeout="timeout"
              v-model="success"
              color="success"
              top
            >
              Daten erfolgreich gespeichert
            </v-snackbar>
          <v-snackbar
              :timeout="timeout"
              v-model="error"
              color="error"
              top
            >
              Fehler beim Speichern der Daten!
            </v-snackbar>
            <v-col cols="12">
                    <v-card
                      elevation="2"
                      >
              <v-carousel 
                  hide-delimiters
                  height="575px"
                  :value="slider"
                  :show-arrows="false">
                  <v-carousel-item
                    v-for="(item,i) in items"
                    :key="i"
                  >
                        <div class="text-h5 text-center" >
                          {{ i+1 }}. Spieltag <br>
                          ({{ formatDates(item.start_date, item.end_date) }})
                        </div>
                        <v-simple-table>
                            <template v-slot:default>
                              <tbody>
                                <tr
                                  v-for="game in item.games"
                                  :key="game.id"
                                >
                                  <td>{{ game.home_user.name }}</td>
                                  <td>{{ game.guest_user.name }}</td>
                                  <td class="text-center">
                                    <v-text-field
                                      v-if="checkUserPermissions(user, game)"
                                      type="number"
                                      :value="game.home_points"
                                      @blur="changePoints($event, game.id, 'home_points')"
                                      >
                                    </v-text-field>
                                    <span v-else>
                                      {{ game.home_points }}
                                    </span>
                                  </td>
                                  <td class="text-center">
                                    <v-text-field
                                      v-if="checkUserPermissions(user, game)"
                                      type="number"
                                      :value="game.guest_points"
                                      @blur="changePoints($event, game.id, 'guest_points')"
                                      >
                                    </v-text-field>
                                    <span v-else>
                                      {{ game.guest_points }}
                                    </span>
                                </td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                  </v-carousel-item>
                </v-carousel>
                    </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { parseISO, format, isAfter } from 'date-fns'
import axios from 'axios'
export default {
  name: 'Admin',
  data() {
      return {
        error:false,
        success:false,
        timeout:0
      }
  },

  methods: {
    formatDates(start, end) {
      start = format(parseISO(start), 'dd.MM')
      end = format(parseISO(end), 'dd.MM')

      return `${start} - ${end}`
    },
    changePoints(e, id, type) {
      axios.post(`https://api.glomb-on-line.de/api/game/days/${id}/points`, {"type": type, "value": e.target.value})
      .then(response => {
          if(response.data.status !== "Success"){
            this.error = true
            this.timeout = 2000
          }
          else{
            this.success = true
            this.timeout = 2000
          }
      })
      .catch(() => {
            this.error = true
            this.timeout = 2000
      });
    },
    checkUserPermissions(user, game){
      let authorized = false
      if(user.user.id === game.home_user.id || user.user.id === game.guest_user.id){
        authorized = true
      }else {
        user.user.permissions.forEach(permission => {
          if(permission.permission === "admin"){
            authorized = true
          }
        })
      }
      return authorized
    }
  },
  computed: {
    user() { return this.$store.state.user },
    items() { return this.$store.state.gameDays },
    slider() {
      var slide = 0
      this.items.forEach((item, key) => {
        if(isAfter(parseISO(item.end_date), new Date()) && slide == 0){
          console.log("Jo")
          slide = key;
        }
      })
      return slide
    }
  },
}
</script>
<style lang="scss" scoped>
    form{
        
    }
</style>