import Vue from 'vue'
import VueRouter from 'vue-router'
import GameTable from '../components/GameTable.vue'
import GameDays from '../components/GameDays.vue'
import Login from '../components/Login.vue'
import Admin from '../components/Admin.vue'
import RealAdmin from '../components/RealAdmin.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'GameTable',
    component: GameTable
  },
  {
    path: '/days',
    name: 'GameDays',
    component: GameDays
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/admin',
    name: 'Admin',
    component: Admin
  },
  {
    path: '/realadmin',
    name: 'RealAdmin',
    component: RealAdmin
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
