<template>
  <v-app>
        <v-app-bar
          color="amber lighten-1"
          dark
          app
        >
          <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>

          <v-toolbar-title>Kniffel</v-toolbar-title>
        </v-app-bar>

        <v-navigation-drawer
          v-model="drawer"
          absolute
          temporary
        >
          <v-list
            nav
            dense
          >
            <v-list-item-group
              v-model="group"
              active-class="amber--text text--lighten-1"
            >
              <v-list-item 
                 link
                 to="/">
                <v-list-item-icon>
                  <v-icon>mdi-format-list-numbered</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Tabelle</v-list-item-title>
              </v-list-item>

              <v-list-item 
                 link
                 to="/days">
                <v-list-item-icon>
                  <v-icon>mdi-calendar</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Spieltag</v-list-item-title>
              </v-list-item>

              <v-list-item 
                 v-if="!isLogged"
                 link
                 to="/login">
                <v-list-item-icon>
                  <v-icon>mdi-calendar-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Login</v-list-item-title>
              </v-list-item>

              <v-list-item 
                 v-else
                 link
                 to="/admin">
                <v-list-item-icon>
                  <v-icon>mdi-calendar-cursor</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Ergebnisse eintragen</v-list-item-title>
              </v-list-item>

              <v-list-item 
                 v-if="checkUserPermissions()"
                 link
                 to="/realadmin">
                <v-list-item-icon>
                  <v-icon>mdi-account-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Administration</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
        <v-main>
          <router-view></router-view>
        </v-main>  
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
  export default {
    data: () => ({
      drawer: false,
      group: null,
    }),
    methods: {
      checkUserPermissions(){
        let authorized = false
        if(this.user && this.user.user.permissions){
          this.user.user.permissions.forEach(permission => {
            if(permission.permission === "admin"){
              authorized = true
            }
          })
        }
        return authorized
      }
    },
    computed: {
        ...mapGetters([
            'isLogged'
        ]),
        user() { return this.$store.state.user },
    },
  }
</script>

<style lang="scss">
</style>
