<template>
    <v-container>
        <v-row>
            <v-col cols="12">
            <v-form @submit="login">
                <v-text-field
                    label="Name"
                    v-model="name"
                  ></v-text-field>
                <v-text-field
                    label="Passwort"
                    type="password"
                    v-model="password"
                  ></v-text-field>
            <v-btn @click="login">Login</v-btn>
        </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    name: 'Login',
    data() {
        return {
            name: '',
            password: ''
        }
    },

    methods: {
        login() {
            this.$store
                .dispatch('login', {
                    name: this.name,
                    password: this.password
                })
                .then(() => {
                    this.$router.push({ name: 'GameTable' })
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }
}
</script>
<style lang="scss" scoped>
</style>