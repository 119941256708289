import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
		gameDays: [],
    table: [],
    allTimeTable: [],
    user: null
  },
  mutations: {

		getGameDays(state) {
			axios
      .get('https://api.glomb-on-line.de/api/game/days')
      .then(({ data }) => {
        state.gameDays = data.data
      })
		},

    setUserData(state, userData) {
        state.user = userData
        localStorage.setItem('user', JSON.stringify(userData))
        axios.defaults.headers.common.Authorization = `Bearer ${userData.token}`
        axios.defaults.withCredentials = true;
    },

    clearUserData() {
        localStorage.removeItem('user')
        location.reload()
    }
  },
  actions: {

    login({ commit }, credentials) {
        axios.get('https://api.glomb-on-line.de/sanctum/csrf-cookie')
        return axios
            .post('https://api.glomb-on-line.de/api/login', credentials)
            .then(({ data }) => {
                commit('setUserData', data)
            })
    },

    logout({ commit }) {
        commit('clearUserData')
    }
  },
  getters: {
    isLogged: state => !!state.user
  }
})
