<template>
    <v-container>
        <v-row>
            <v-col cols="12">
                    <v-card
                      elevation="2"
                      >
              <v-carousel 
                  :value="slider"
                  hide-delimiters
                  :show-arrows="false">
                  <v-carousel-item
                    v-for="(item,i) in items"
                    :key="i"
                  >
                        <div class="text-h5 text-center" >
                          {{ i+1 }}. Spieltag <br>
                          ({{ formatDates(item.start_date, item.end_date) }})
                        </div>
                        <v-simple-table>
                            <template v-slot:default>
                              <tbody>
                                <tr
                                  v-for="game in item.games"
                                  :key="game.id"
                                >
                                  <td>{{ game.home_user.name }}</td>
                                  <td>{{ game.guest_user.name }}</td>
                                  <td>{{ game.home_points }}</td>
                                  <td>{{ game.guest_points }}</td>
                                </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                  </v-carousel-item>
                </v-carousel>
                    </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { parseISO, format, isAfter } from 'date-fns'
export default {
  name: 'GameDaysPlayer',
  data() {
      return {
      }
  },

  methods: {
    formatDates(start, end) {
      start = format(parseISO(start), 'dd.MM')
      end = format(parseISO(end), 'dd.MM')

      return `${start} - ${end}`
    }
  },
  computed: {
    items() { return this.$store.state.gameDays },
    slider() {
      var slide = 0
      this.items.forEach((item, key) => {
        if(isAfter(parseISO(item.end_date), new Date()) && slide == 0){
          slide = key;
        }
      })
      return slide
    }
  },
}
</script>
<style lang="scss" scoped>
    form{
        
    }
</style>